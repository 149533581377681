<template>
  <div class="program-list">
    <table>
      <tr class="table-header">
        <th>Program</th>
        <th>Points</th>
        <th>Description</th>
      </tr>
      <tr class="table-content" v-for="(item, $i) in list" :key="$i">
        <td>
          <div
            class="program-img"
            :style="{
              background: 'url(' + item.business.businessLogoUrl + ')',
              'background-size': 'cover',
              'background-position': 'center',
            }"
          ></div>
          <router-link :to="{path:`${(item.id)}`}">{{ item.name }}</router-link>
        </td>
        <td>{{ item.points }}</td>
        <td>{{ item.description }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "ProgramsList",
  props: {
    list: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.program-list {
  table {
    width: 100%;
    border-spacing: 0;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 deg(0deg 0% 62% / 33%);
    .table-header {
      background: #e9e9e9;
      height: 80px;
      th {
        margin: 0;
        text-align: center;
        padding: 20px;
      }
    }
    .table-content {
      td {
        text-align: center;
        border-bottom: 1px solid #e9e9e9;
        padding: 0 20px;
        &:nth-child(1) {
          display: flex;
          align-items: center;
          height: 100px;
          .program-img {
            width: 40px;
            height: 35px;
            border-radius: 100%;
          }
          a{
            color: black;
            padding: 0 10px;
          }
        }
        &:nth-child(4) {
          padding: 40px;
          text-align: left;
        }
      }
    }
  }
}
</style>
