<template>
  <div class="programs">
    <div class="programs-main">
      <div class="search-container">
        <form>
          <input type="text" class="search" />
          <button @click="search()" type="submit">
            <i class="flaticon-magnifying-glass"></i>
          </button>
        </form>
      </div>
      <div class="programs-list">
        <ProgramsTables :list="programs"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramsTables from '@/components/dashboard/programs-list.vue'
import axios from "axios";
export default {
  name: "Programs",
  data() {
    return {
      programs: []
    };
  },
  methods: {
    search() {
      console.log("hola");
    },
  },
  components: {
    ProgramsTables
  },
  mounted(){
    return axios
      .get("https://jama.contreras.website/api/v1/programs")
      .then((r) => {
        this.programs = r.data.items;
      })
      .catch((e) => {
        console.log(e);
      });
  }
};
</script>

<style lang="scss" scoped>
.programs {
  .programs-main {
    padding: 60px;
    .search-container {
      width: 100%;
      padding: 0 0 50px 0;
      form {
        height: 30px;
        width: 300px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        .search {
          height: 100%;
          width: 100%;
          border-radius: 6px;
          border: 1px solid #314950;
          padding: 0 10px;
        }
        button {
          height: 100%;
          border: 1px solid #314950;
          background-color: #222ac3;
          border-radius: 6px;
          width: 50px;
          right: -5px;
          top: 2px;
          cursor: pointer;
          i {
            color: white;
          }
        }
      }
    }
  }
}
</style>
